import {useEffect, useState} from "react";
import StudentRegistration from "../StudentRegistration";
import Register1 from "../Register1";

const AccountRegistration = ({}) =>{

    const [isScopedToSchools, setIsScopedToSchools] = useState(false);
    useEffect(() => {
        const siteConfig = JSON.parse(localStorage.getItem('siteConfig')?? '{}');
        setIsScopedToSchools(siteConfig?.isScopedToSchools === true);
    }, []);
    return(
        <div>
            {isScopedToSchools ? <StudentRegistration/> : <Register1/>}
        </div>
    );
}

export default AccountRegistration;