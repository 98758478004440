import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Col from 'react-bootstrap/Col';
import {useNavigate, useOutletContext} from 'react-router-dom';
import {useState, useEffect} from 'react';
import LoadingSpinner from '../Components/LoadingSpinner';
import Page from "./Page";
import {isScopedToSchools} from "../services/site-settings";
import {Modal} from "react-bootstrap";

const ConfirmRegistration = () => {
    const navigate = useNavigate();
    const [details, setDetails] = useOutletContext();
    const [schoolOrg, setSchoolOrg] = useState("NONE_ENTERED");
    const [errorOccurred, setErrorOccured] = useState(false);
    const [showPage, setShowPage] = useState(false);
    const [scopedToSchools, setScopedToSchools] = useState(false);

    const [showSurvey, setShowSurvey] = useState(false);

    function finishSurvey() {
        navigate("/")
    }

    useEffect(() => {
        addPlayer();
        if (isScopedToSchools()) {
            setScopedToSchools(true);
        }
    }, []);

    const addPlayer = async () => {
        if (!details.email1 || !details.p1) {
            navigate('/register')
        }
        var location = details.loc;
        if (details.loc === "Other") {
            location = details.otherLocation;
        }

        if (details.schOrg !== '') {
            setSchoolOrg(details.schOrg);
        }
        const response = await fetch('/api/addNewAccount/', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                first_name: `${details.fName}`,
                last_name: `${details.lName}`,
                email: `${details.email1}`,
                password: `${details.p1}`,
                playername: `${details.pName}`,
                gender: `${details.gender}`,
                age_group: `${details.ageGroup}`,
                type: `${details.playerType}`,
                location: `${location}`,
                org: `${schoolOrg}`,
                school: details.school,
                recommendedBy: details.nameOfTeacher,
                age: details.age,
                nationality: details.nationality,
                secondarySchoolAccount: details.secondarySchoolAccount
            })
        });
        const data = await response.json();
        if (data.message !== "USER_REGISTRATION_DETAILS_ADDED")
            setErrorOccured(true);
        setShowPage(true);
    }

    return (
        <>
            <Page title='Registration - Confirm'/>
            <Container fluid>
                {(showPage) ?
                    <>
                        <Row className='mt-4'>
                            <Col sm={12} md={1} className="d-none d-md-block"> </Col>
                            <Col sm={12} md={10}>  {/* Main section */}

                                <Row
                                    className="align-items-center homepage-header-bg border border-1 border-secondary "> {/* header row*/}
                                    <Col xs={12} sm={4} className=" text-center  my-3">

                                        <Image src={require('../assets/logos/IQlogo.png')} width="70%" height="auto"/>
                                    </Col>
                                    <Col xs={12} sm={8}>
                                        {(errorOccurred) ?
                                            <p className='fs-3 roboto-font text-light text-center my-2'> Creating an
                                                Account</p>
                                            :
                                            <p className='fs-3 roboto-font text-light text-center my-2'> Thank You For
                                                Signing Up!</p>
                                        }
                                    </Col>

                                </Row> {/* close header row*/}

                                <Row className='border border-2 border-dark mb-5'> {/* page body row*/}
                                    <Col>
                                        {(errorOccurred) ?
                                            <>
                                                <p className='text-center fs-4 my-4 currency'>An error has occured an we
                                                    are unable to create your account. </p>

                                                <p className='text-center text-primary fs-5 my-4 currency'>
                                                    *Please ensure that the email you have entered is valid. </p>

                                                <div className='text-end mb-3 mx-3'>
                                                    <Button variant='warning' size='sm' onClick={() => navigate("/")}>
                                                        Back to Home
                                                    </Button>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <p className='text-center fs-4 my-4 currency'>We have sent you a
                                                    confirmation email to activate your
                                                    account. This link will expire after 24 hours. </p>

                                                <p className='text-center text-primary fs-5 my-4 currency'>
                                                    *Remember to check your junk or spam folder if the email is not in
                                                    your inbox. </p>

                                                {
                                                    scopedToSchools && <div className="text-center">
                                                        <Button variant='warning' size='sm'
                                                                href="https://forms.office.com/r/1afcnchr5D"
                                                                target="_blank">
                                                            Take Our Survey
                                                        </Button>
                                                    </div>
                                                }


                                                <div className='text-end mb-3 mx-3'>
                                                    <div>
                                                        <Button variant='warning' size='sm'
                                                                onClick={() => navigate("/")}>
                                                            Continue
                                                        </Button>
                                                    </div>
                                                </div>

                                            </>
                                        }


                                    </Col>

                                </Row>{/* page body row*/}
                            </Col>
                            <Col sm={12} md={1} className="d-none d-md-block"> </Col>
                        </Row>
                    </>
                    :
                    <>
                        <div className="p-3 mb-3 text-center">
                            <LoadingSpinner/>
                        </div>
                    </>
                }

            </Container>
            <Modal show={showSurvey} fullscreen="xxl-down" onHide={finishSurvey} centered backdrop='static'
                   keyboard={false} size="lg" scrollable={false}>
                <Modal.Header closeButton className='py-1'>
                    <Modal.Title className='currency fs-3 text-center'>
                        Take Our Survey
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='currency fs-5'>
                    <iframe height="95%" width="100%" src="https://forms.office.com/r/1afcnchr5D"></iframe>
                </Modal.Body>
            </Modal>


        </>
    );
}

export default ConfirmRegistration;