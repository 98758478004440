import {useEffect, useState} from "react";
import StudentRegistration from "../StudentRegistration";
import Register1 from "../Register1";
import {useNavigate} from "react-router-dom";

const SchoolsRegistration = ({scope}) =>{
    const navigate = useNavigate();
    useEffect(() => {
        const siteConfig = JSON.parse(localStorage.getItem('siteConfig')?? '{}');
        siteConfig.isScopedToSchools = true;
        localStorage.setItem('siteConfig', JSON.stringify(siteConfig));
        navigate("/register");
    }, []);
    return(
        <div></div>
    );
}

export default SchoolsRegistration;